export default defineNuxtRouteMiddleware((to, _from) => {
  const { appUserMe } = useAppUserStore();
  const { getActiveWorkspace } = useWorkspace();
  const activeWorkspace = getActiveWorkspace(appUserMe);
  if (!activeWorkspace) {
    return;
  }
  if (activeWorkspace.subscriptionExpired) {
    if (
      to.path.includes("/payment") ||
      to.path.includes("/auth") ||
      to.path.includes("/signup") ||
      to.path.includes("/legal")
    ) {
      return;
    }
    return navigateTo("/payment/subscription-expired");
  }
});
