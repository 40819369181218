<template>
  <button
    v-tooltip="tooltip"
    :disabled="loading"
    type="button"
    :class="[
      'rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
      primary
        ? 'bg-primary-400 hover:bg-primary-300 focus-visible:ring-primary-400 border border-primary-400'
        : neutral
          ? 'bg-gray-50 hover:bg-gray-100 border border-gray-300'
          : 'bg-transparent hover:bg-gray-200 focus-visible:outline-gray-200 border border-transparent',
      padding,
    ]"
    @click.prevent="onClick"
  >
    <slot aria-hidden="true" />
  </button>
</template>

<script setup lang="ts">
import { PropType } from "vue";

const { tooltip, preventDefault, stopPropagation } = defineProps({
  tooltip: {
    type: String as PropType<string | null>,
    default: null,
  },
  preventDefault: {
    type: Boolean,
    default: true,
  },
  stopPropagation: {
    type: Boolean,
    default: false,
  },
  primary: {
    type: Boolean,
    default: false,
  },
  neutral: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: "p-1",
  },
});

const emit = defineEmits(["click"]);

const onClick = (event: any) => {
  if (preventDefault) {
    event.preventDefault();
  }
  if (stopPropagation) {
    event.stopPropagation();
  }
  emit("click");
};
</script>
