export enum AppUserStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  PENDING_INVITATION = "PENDING_INVITATION",
}

export enum AppUserRole {
  ADMIN = "ADMIN",
  VIEWEDIT = "VIEWEDIT",
  VIEWONLY = "VIEWONLY",
  CREATOR = "CREATOR",
}

// Legacy from v1. Keep around in case someone is assigned to this role.
export const DISABLED_ROLES = [AppUserRole.CREATOR];

export interface AppUserResponse {
  id: number;
  uuid: string;
  email: string;
  role: AppUserRole;
  status: AppUserStatus;
  createdBy: string;
  createdAt: string;
  firstname?: string | undefined | null;
  lastname?: string | undefined | null;
  description?: string | undefined | null;
  profileImage?: string | undefined | null;
  position?: string | undefined | null;
  activeWorkspace: number;
  internal: boolean;
  activeClientId: number | null;
}

export interface AppUser {
  id: number;
  uuid: string;
  email: string;
  displayName: string;
  status: AppUserStatus;
  role: AppUserRole;
  createdAt: string;
  createdBy: string;
  firstname?: string | undefined | null;
  lastname?: string | undefined | null;
  description?: string | undefined | null;
  profileImage: string;
  position?: string | undefined | null;
  displayStatus: string;
  displayRole: string;
  activeWorkspace: number;
  internal: boolean;
  activeClientId: number | null;
}

export const statusToDisplayStatus = {
  [AppUserStatus.ACTIVE]: "Active",
  [AppUserStatus.DISABLED]: "Disabled",
  [AppUserStatus.PENDING_INVITATION]: "Invitation pending",
};

export const userRoleToDisplayRole = {
  [AppUserRole.ADMIN]: "Admin",
  [AppUserRole.VIEWEDIT]: "Member",
  [AppUserRole.VIEWONLY]: "Visitor",
  [AppUserRole.CREATOR]: "Creator",
};

export const userRoleToClass: Record<AppUserRole, string> = {
  ADMIN: "text-primary-400 bg-primary-50 ring-primary-600/20",
  VIEWEDIT: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
  VIEWONLY: "text-gray-600 bg-gray-50 ring-gray-500/10",
  CREATOR: "text-green-700 bg-green-50 ring-green-600/20",
};

export const userRoleToTooltip: Record<AppUserRole, string> = {
  ADMIN:
    "Can create/edit/delete/read everything. " +
    "Has access to admin area to manage connections, clients, users. " +
    "Grants access to clients for non-admin users.",
  VIEWEDIT:
    "Can create/edit/read/delete reports and insights where he/she is granted access to.",
  VIEWONLY: "Can read reports where he/she is granted access to.",
  CREATOR:
    "Can read briefings, comment on briefings, upload deliverables to briefings where he/she is granted access to.",
};

export const VISITOR: AppUser = {
  id: -1,
  uuid: "visitor",
  role: AppUserRole.VIEWONLY,
  activeWorkspace: -1,
  internal: false,
  email: "visitor@share.page",
  displayName: "Visitor",
  status: AppUserStatus.ACTIVE,
  createdAt: new Date().toISOString(),
  createdBy: "system",
  profileImage: "https://ui-avatars.com/api/?name=Visitor&size=128",
  displayStatus: statusToDisplayStatus[AppUserStatus.ACTIVE],
  displayRole: userRoleToDisplayRole[AppUserRole.VIEWONLY],
  activeClientId: null,
};
