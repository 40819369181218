import { createAvatar } from "~/shared/utils";
import {
  AppUser,
  AppUserResponse,
  AppUserRole,
  userRoleToTooltip,
  statusToDisplayStatus,
  userRoleToDisplayRole,
  DISABLED_ROLES,
} from "~/types/AppUser";

export const useAppUser = () => {
  const isAuthenticated = () => {
    const { status } = useAuth();
    return status.value === "authenticated";
  };

  const getMappedAppUsers = (
    users: Array<AppUserResponse>,
    loggedInUser: AppUser | undefined | null,
  ): Array<AppUser> => {
    const mappedUsers = users
      .filter((user) => {
        // For all customer workspaces show only not internal users
        // Remember: Return true means keep it
        return loggedInUser == null || loggedInUser.internal || !user.internal;
      })
      .map((user) => {
        const displayName = getUserDisplayName(
          user.firstname,
          user.lastname,
          user.email,
        );

        const displayImage =
          typeof user.profileImage === "string" && user.profileImage.length > 0
            ? user.profileImage
            : createAvatar(displayName, "initials");

        return {
          id: user.id,
          uuid: user.uuid,
          email: user.email,
          displayName,
          role: user.role,
          status: user.status,
          createdAt: user.createdAt,
          createdBy: user.createdBy,
          description: user.description,
          position: user.position,
          profileImage: displayImage,
          firstname: user.firstname,
          lastname: user.lastname,
          displayStatus: statusToDisplayStatus[user.status],
          displayRole: userRoleToDisplayRole[user.role],
          activeWorkspace: user.activeWorkspace,
          internal: user.internal,
          activeClientId: user.activeClientId,
        };
      });

    mappedUsers.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedUsers;
  };

  const getUserDisplayName = (
    firstname: string | undefined | null,
    lastname: string | undefined | null,
    email: string,
  ) => {
    if (firstname && lastname) {
      return firstname + " " + lastname;
    } else if (firstname) {
      return firstname;
    } else if (lastname) {
      return lastname;
    } else {
      return email;
    }
  };

  const isAdmin = (appUserMe: AppUser | null) => {
    if (appUserMe) {
      return appUserMe.role === AppUserRole.ADMIN;
    }
    return false;
  };

  const isMember = (appUserMe: AppUser | null) => {
    if (appUserMe) {
      return appUserMe.role === AppUserRole.VIEWEDIT;
    }
    return false;
  };

  const getRoles = () => {
    return Object.values(AppUserRole)
      .filter((role) => !DISABLED_ROLES.includes(role))
      .map((userRole) => {
        return {
          displayValue: userRoleToDisplayRole[userRole],
          description: userRoleToTooltip[userRole],
          value: userRole,
        };
      });
  };

  return {
    isAuthenticated,
    getMappedAppUsers,
    getUserDisplayName,
    isAdmin,
    isMember,
    getRoles,
  };
};
