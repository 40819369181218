/* eslint-disable no-console */
import { AnalyticsBrowser } from "@segment/analytics-next";
import {
  NavigationFailureType,
  NavigationFailure,
  RouteLocationNormalized,
} from "vue-router";
import { useSegment } from "../composables/useSegment";

let segment: AnalyticsBrowser;
const debugEnabled = false;
const trackOnNextTick = false;

export default defineNuxtPlugin((nuxt) => {
  const segmentWriteKey = nuxt.$config.public.segmentWriteKey;

  if (!process.client) {
    return {};
  }

  if (!segmentWriteKey) {
    console.warn(
      `[segment]: segmentWriteKey is empty - cannot initialise segment plugin`,
    );
    return {};
  }

  segment = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

  initVueRouterTracking();

  return {
    provide: {
      segment,
    },
  };
});

function isNavigationFailure(
  failure: void | NavigationFailure | undefined,
  navigationFailureType:
    | NavigationFailureType.aborted
    | NavigationFailureType.cancelled
    | NavigationFailureType.duplicated,
): boolean {
  if (!(failure instanceof Error)) {
    return false;
  }
  return !!(failure.type & navigationFailureType);
}

function fullUrlFromRoute(route: RouteLocationNormalized, baseUrl = "") {
  let fullUrl: string = baseUrl;
  if (!fullUrl.endsWith("/")) {
    fullUrl += "/";
  }
  fullUrl += route.fullPath.startsWith("/")
    ? route.fullPath.substring(1)
    : route.fullPath;

  return fullUrl;
}

function initVueRouterTracking() {
  const router = useRouter();

  const baseUrl: string = router.options?.history?.base ?? "";

  router.afterEach((to, from, failure) => {
    const name: string = trackNameFromRoute(to);

    if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
      if (debugEnabled) {
        console.debug(
          `[Segment]: '${name}' not tracked due to navigation aborted`,
        );
      }
    } else if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
      if (debugEnabled) {
        console.debug(
          `[Segment]: '${name}' not tracked due to navigation cancelled`,
        );
      }
    }

    const additionalEventData: Record<string, any> = {
      ...(to.meta?.segmentAdditionalEventData as Record<string, any>),
    };

    const fullToUrl = fullUrlFromRoute(to, baseUrl);
    const fullFromUrl = fullUrlFromRoute(from, baseUrl);

    const properties = {
      path: fullToUrl,
      title: to.name,
      url: fullToUrl,
      referrer: fullFromUrl,
      ...additionalEventData,
    };

    trackRoute(name, properties);
  });
}

async function trackRoute(name: string, properties?: any) {
  const { page: trackPage } = useSegment(segment);

  if (trackOnNextTick) {
    nextTick(() => {
      trackPage("screenViewed", name, properties);
    });
  } else {
    await trackPage("screenViewed", name, properties);
  }
}

function trackNameFromRoute(to: RouteLocationNormalized): string {
  // Dispatch vue event using meta segment value if defined otherwise fallback to route name, or finally route path
  const name =
    to.meta && typeof to.meta.segment === "string" && !!to.meta.segment
      ? to.meta.segment
      : (to.name as string | undefined);

  return name ?? to.path;
}
