import { IFlagsmith } from "flagsmith/types";
import {
  FeatureFlag,
  isEnabledOnLocalDev,
  isEnabledOnSharePage,
} from "~/types/shared";

export const getLocalFlagsmith = (): IFlagsmith<string, string> => {
  return {
    init: () => Promise.resolve(),
    getFlags: () => Promise.resolve(),
    getAllFlags: () => ({}) as any,
    identify: () => Promise.resolve(),
    getState: () => ({}) as any,
    setState: () => {},
    logout: () => Promise.resolve(),
    startListening: () => {},
    stopListening: () => {},
    hasFeature: (key: string) => {
      return isEnabledOnLocalDev[key as FeatureFlag];
    },
    getValue: () => ({}) as any,
    getTrait: () => ({}) as any,
    getAllTraits: () => ({}) as any,
    setTrait: () => Promise.resolve(),
    setTraits: () => Promise.resolve(),
    log: () => {},
    cacheOptions: {
      ttl: 0,
      skipAPI: false,
    },
    api: "https://api.flagsmith.com/api/v1/",
    environmentID: "",
  };
};

export const getSharePageFlagsmith = (): IFlagsmith<string, string> => {
  return {
    init: () => Promise.resolve(),
    getFlags: () => Promise.resolve(),
    getAllFlags: () => ({}) as any,
    identify: () => Promise.resolve(),
    getState: () => ({}) as any,
    setState: () => {},
    logout: () => Promise.resolve(),
    startListening: () => {},
    stopListening: () => {},
    hasFeature: (key: string) => {
      return isEnabledOnSharePage[key as FeatureFlag];
    },
    getValue: () => ({}) as any,
    getTrait: () => ({}) as any,
    getAllTraits: () => ({}) as any,
    setTrait: () => Promise.resolve(),
    setTraits: () => Promise.resolve(),
    log: () => {},
    cacheOptions: {
      ttl: 0,
      skipAPI: false,
    },
    api: "https://api.flagsmith.com/api/v1/",
    environmentID: "",
  };
};
