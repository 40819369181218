/* eslint-disable no-console */
import type { AnalyticsBrowser } from "@segment/analytics-next";
import type {} from "@segment/analytics-core";
import { AppUser } from "~/types/AppUser";
import { Workspace } from "~/types/Workspace";

const SHARE_PAGE_VISITOR_PREFIX = "share-page-visitor";

export function useSegment(segment?: AnalyticsBrowser) {
  const $segment = segment ?? useNuxtApp().$segment;
  const segmentWriteKey = useRuntimeConfig().public.segmentWriteKey;
  const containerAppsEnvironment =
    useRuntimeConfig().public.containerAppsEnvironment;
  const logPrefix = shouldTrack() ? "segment" : "segment-mock";
  const debugEnabled = useRuntimeConfig().public.segmentDebugEnabled;
  const AnalyticsEvents = {
    SHARE_TOPCREATIVE_REPORT: "SHARE_TOPCREATIVE_REPORT",
    SHARE_COMPARISON_REPORT: "SHARE_COMPARISON_REPORT",
    SHARE_INSPIRATION_BOARD: "SHARE_INSPIRATION_BOARD",
    SHARE_INSPIRATION_BOARD_ASSET: "SHARE_INSPIRATION_BOARD_ASSET",
    SHARE_TESTING_LOG_REPORT: "SHARE_TESTING_LOG_REPORT",
    SHARE_LAUNCH_REPORT: "SHARE_LAUNCH_REPORT",
    SNAPSHOT_TOPCREATIVE_REPORT: "SNAPSHOT_TOPCREATIVE_REPORT",
    SNAPSHOT_COMPARISON_REPORT: "SNAPSHOT_COMPARISON_REPORT",
    SNAPSHOT_TESTING_LOG_REPORT: "SNAPSHOT_TESTING_LOG_REPORT",
    SNAPSHOT_LAUNCH_REPORT: "SNAPSHOT_LAUNCH_REPORT",
    VIEW_SHARED_TOPCREATIVE_REPORT: "VIEW_SHARED_TOPCREATIVE_REPORT",
    VIEW_SHARED_COMPARISON_REPORT: "VIEW_SHARED_COMPARISON_REPORT",
    VIEW_SHARED_INSPIRATION_BOARD: "VIEW_SHARED_INSPIRATION_BOARD",
    VIEW_SHARED_INSPIRATION_BOARD_ASSET: "VIEW_SHARED_INSPIRATION_BOARD_ASSET",
    VIEW_SHARED_TESTING_LOG_REPORT: "VIEW_SHARED_TESTING_LOG_REPORT",
    VIEW_SHARED_LAUNCH_REPORT: "VIEW_SHARED_LAUNCH_REPORT",
    VIEW_SNAPSHOT_TOPCREATIVE_REPORT: "VIEW_SNAPSHOT_TOPCREATIVE_REPORT",
    VIEW_SNAPSHOT_COMPARISON_REPORT: "VIEW_SNAPSHOT_COMPARISON_REPORT",
    VIEW_SNAPSHOT_TESTING_LOG_REPORT: "VIEW_SNAPSHOT_TESTING_LOG_REPORT",
    VIEW_SNAPSHOT_LAUNCH_REPORT: "VIEW_SNAPSHOT_LAUNCH_REPORT",
  };

  function shouldTrack() {
    return (
      process.client && segmentWriteKey && containerAppsEnvironment === "prod"
    );
  }

  // See: https://segment.com/docs/connections/spec/identify/
  function identify(...args: Parameters<AnalyticsBrowser["identify"]>) {
    wrapFn("identify")(...args);
  }

  // See: https://segment.com/docs/connections/spec/alias/
  function alias(...args: Parameters<AnalyticsBrowser["alias"]>) {
    wrapFn("alias")(...args);
  }

  // See: https://segment.com/docs/connections/spec/page/
  function page(...args: Parameters<AnalyticsBrowser["page"]>) {
    wrapFn("page")(...args);
  }

  // See: https://segment.com/docs/connections/spec/screen/
  function screen(...args: Parameters<AnalyticsBrowser["screen"]>) {
    wrapFn("screen")(...args);
  }

  // See: https://segment.com/docs/connections/spec/group/
  function group(...args: Parameters<AnalyticsBrowser["group"]>) {
    wrapFn("group")(...args);
  }

  // See: https://segment.com/docs/connections/spec/track/
  function track(...args: Parameters<AnalyticsBrowser["track"]>) {
    wrapFn("track")(...args);
  }

  function trackSubmit(...args: Parameters<AnalyticsBrowser["trackSubmit"]>) {
    wrapFn("trackSubmit")(...args);
  }

  function trackClick(...args: Parameters<AnalyticsBrowser["trackClick"]>) {
    wrapFn("trackClick")(...args);
  }

  function trackLink(...args: Parameters<AnalyticsBrowser["trackLink"]>) {
    wrapFn("trackLink")(...args);
  }

  // See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-log-out
  function reset(...args: Parameters<AnalyticsBrowser["reset"]>) {
    return wrapFn("reset")(...args);
  }

  function raw(_name: string, ...args: any) {
    return wrapFn("raw")(...args);
  }

  function wrapFn(fnName: string) {
    return function analyticsFn(...args: any) {
      if (shouldTrack()) {
        // @ts-ignore-next-line
        return $segment[fnName](...args);
      }
      if (debugEnabled) {
        console.info(`[${logPrefix}@${fnName}]`, ...args);
      }
    };
  }

  const getAnalyticsUser = (user: AppUser) => {
    return {
      userId: user.id.toString(),
      traits: {
        name: user.email,
        email: user.email,
        createdAt: new Date(user.createdAt),
        role: user.role,
        workspaceId: user.activeWorkspace,
        uuid: user.uuid,
        internal: user.internal,
      },
    };
  };

  const getAnalyticsWorkspace = (
    userId: number | string,
    workspace: Workspace,
  ) => {
    return {
      userId: userId.toString(),
      groupId: workspace.id.toString(),
      traits: {
        name: workspace.displayName,
        avatar: workspace.logo ?? undefined,
      },
    };
  };

  const identifySharePageWorkspace = (workspaceId: number) => {
    const userId = SHARE_PAGE_VISITOR_PREFIX + "-" + workspaceId;
    identify(userId);
    group({ userId, groupId: workspaceId.toString() });
  };

  return {
    $segment,
    identify,
    alias,
    page,
    screen,
    group,
    track,
    trackSubmit,
    trackClick,
    trackLink,
    reset,
    raw,
    getAnalyticsUser,
    getAnalyticsWorkspace,
    AnalyticsEvents,
    identifySharePageWorkspace,
  };
}
