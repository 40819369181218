import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45subscription_45global from "/usr/src/app/middleware/01.subscription.global.ts";
import _02_45agb_45global from "/usr/src/app/middleware/02.agb.global.ts";
import _03_45feature_45guard_45global from "/usr/src/app/middleware/03.featureGuard.global.ts";
import _04_45redirects_45global from "/usr/src/app/middleware/04.redirects.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45subscription_45global,
  _02_45agb_45global,
  _03_45feature_45guard_45global,
  _04_45redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "has-clients": () => import("/usr/src/app/middleware/hasClients.ts"),
  logout: () => import("/usr/src/app/middleware/logout.ts"),
  auth: () => import("/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}