export default defineNuxtRouteMiddleware((to, _from) => {
  const { appUserMe } = useAppUserStore();
  const { getActiveWorkspace } = useWorkspace();
  const activeWorkspace = getActiveWorkspace(appUserMe);
  if (!activeWorkspace) {
    return;
  }
  if (!activeWorkspace.acceptedAgb) {
    if (
      to.path.includes("/legal") ||
      to.path.includes("/auth") ||
      to.path.includes("/signup") ||
      to.path.includes("/payment")
    ) {
      return;
    }
    return navigateTo("/legal/accept-agb");
  }
});
